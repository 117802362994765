import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import * as SalesShopService from "../../../services/saleShopService";
import * as productService from '../../../services/productServices';
import { symbol } from '../../../config/config';
import { warningNotify } from '../../../config/notify';
import style from "./addOrEditSalesShop.module.css";
import { productCalculation } from '../../../util/calculation';
import { salesShopActions } from '../../../store/salesShop';
import { Delete } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddOrEditSalesShop({ title, modalOpen, action, saleShopId }) {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);
    const salesShop = useSelector((state) => state.salesShop.salesShop);
    const isLoading = useSelector((state) => state.salesShop.isLoading);
    const [deleteSales, setDeleteSales] = useState([]);

    const [price, setPrice] = useState();
    const [quantity, setQuantity] = useState(0);
    const [product, setProduct] = useState("");
    const [selectedProduct, setSelectedProduct] = useState();
    const [amount, setAmount] = useState(0);
    const [isCalculateInService, setIsCalculateInService] = useState(true);
    const [modifiedProductsArray, setModifiedProductsArray] = useState([]);
    const [remainingQuantity, setRemainingQuantity] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);

    const [dealOfTheDayDateValidation, setDealOfTheDayDateValidation] = useState(false);
    const [isEnabledDiscount, setIsEnabledDiscount] = useState(false);
    const [discountObject, setDiscountObject] = useState({});
    const [selectedSalesShop, setSelectedSalesShop] = useState({});

    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [size, setSize] = useState("");
    const [color, setColor] = useState("");
    const [discount, setDiscount] = useState(0);
    const [items, setItems] = useState([]);

    const [addSalesArray, setAddSalesArray] = useState([]);

    useEffect(() => {
        dispatch(productService.getProducts());
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            const newProductsArray = [];
            products.forEach(product => {
                newProductsArray.push({ value: product._id, label: product.name });
            });
            setModifiedProductsArray(newProductsArray);
        }
    }, [products]);

    useEffect(() => {
        let calculateTotal = 0;
        let discountTotal = 0;
        addSalesArray.forEach(el => {
            calculateTotal = calculateTotal + +el.amount;
            discountTotal = discountTotal + +el.discount;
        });
        setTotalDiscount(discountTotal);
        setTotalAmount(calculateTotal);
    }, [addSalesArray])


    useEffect(() => {
        console.log(saleShopId)
        if (saleShopId) {
            const saleShop = salesShop.find(el => el._id === saleShopId);
            console.log(saleShop);
            setAddSalesArray(saleShop.data);
        }
    }, [saleShopId]);

    useEffect(() => {
        const productItem = products.find(el => el._id === product.value);
        setSelectedProduct(productItem);
        if (productItem) {
            setItems(productItem.items);
            setPrice(productItem.price);
            setSizes([...new Set(productItem?.items.map(el => el?.size))]);
            if (action === "create") {
                if (productItem?.isEnabledDealOfTheDay) {
                    const countdownDate = new Date(productItem?.dealOfTheDate).getTime();
                    const now = new Date().getTime();
                    setDealOfTheDayDateValidation(countdownDate >= now);
                } else {
                    setDealOfTheDayDateValidation(false);
                }
                setPrice(productItem.price);
                setSize("");
                setQuantity(0);
                setDiscount(0);
                setAmount(0);
                setColor("");
                setIsEnabledDiscount(productItem?.isEnabledDiscount);
                setDiscountObject(productItem?.discount);
            }
        }
    }, [product]);

    useEffect(() => {
        const filterItems = items.filter(el => el.size === size);
        console.log(filterItems);
        setColors(filterItems.map(el => el?.colors?.value));
        setColor("");
        console.log(filterItems.map(el => el?.colors?.value));
    }, [size]);

    useEffect(() => {
        const order = salesShop.find(el => el._id === saleShopId);
        let reQuantity = +selectedProduct?.items.find(el => el.size === size && el?.colors?.value === color)?.quantity || 0;
        console.log(selectedProduct?.items.find(el => el.size === size && el?.colors?.value === color)?.quantity);
        setRemainingQuantity(reQuantity);
        // setColors(((selectedProduct?.items.find(el => el.size === size)?.colors) || [])?.map(el => el.value) || []);
    }, [size, color]);

    useEffect(() => {
        // if (action === "create") {
            if (isCalculateInService && selectedProduct) {
                // setPrice(selectedProduct.price);
            }

            if (price > 0 && quantity > 0 && selectedProduct && isCalculateInService) {
                const calculation = productCalculation(dealOfTheDayDateValidation, selectedProduct, quantity);
                setDiscount(calculation.discount);
                setAmount(calculation.amount);
            }
        // }
    }, [quantity, isCalculateInService]);

    const handleClose = () => {
        dispatch(salesShopActions.modalHandler(false));
    };

    const isValidationFailed = () => {
        if (!product.value) {
            warningNotify("Please select a product.");
            return true;
        }
        if (!color) {
            warningNotify("Please pick a color.");
            return true;
        }
        if (!quantity) {
            warningNotify("Please enter the quantity.");
            return true;
        }
        if (quantity > remainingQuantity) {
            warningNotify("Please enter the quantity low than remaining quantity.");
            return true;
        }
        if (!size) {
            warningNotify("Please provide a size.");
            return true;
        }
        if (!isCalculateInService && !price) {
            warningNotify("Please specify a price.");
            return true;
        }
        if (!isCalculateInService && !amount) {
            warningNotify("Please input an amount.");
            return true;
        }
        return false;
    }

    const createSaleShop = () => {
        dispatch(salesShopActions.loadingHandler(true));
        if (!addSalesArray.length) {
            warningNotify("Please add a item.");
            return true;
        }
        dispatch(SalesShopService.createSaleShop({ sales: addSalesArray }));
    }

    const updateSaleShop = () => {
        dispatch(salesShopActions.loadingHandler(true));
        if (deleteSales.length > 0) {
            for (const sale of deleteSales) {
                dispatch(SalesShopService.deleteSaleShop(sale, ""));
            }
        };

        dispatch(SalesShopService.updateSaleShop(addSalesArray, saleShopId));
    }

    const quantityHandler = (value) => {
        if (!product) {
            return alert("Please select a product");
        }
        setQuantity(value);
    }

    const priceCal = () => {
        if (price > 0 && quantity > 0 && selectedProduct) {
            const calculation = productCalculation(dealOfTheDayDateValidation, selectedProduct, quantity);
            setDiscount(calculation.discount);
            setAmount(calculation.amount);
        }
    };

    const checkHandler = (value) => {
        setIsCalculateInService(value);
        if (value) {
            priceCal();
        };
    }

    const addSale = () => {
        if (isValidationFailed()) {
            return;
        }

        setAddSalesArray([...addSalesArray, {
            product: product.value,
            color,
            quantity,
            size,
            amount,
            price,
            productName: product.label,
            discount: (+price * +quantity - amount)
        }]);

        resetItems();
    }

    const resetItems = () => {
        // setSelectedProduct("");
        setColor("");
        setSize("");
        setQuantity("");
        setAmount("");
        setProduct("");
        setPrice("");
    }

    const removeItem = (item) => {
        let filterItems = [];
        console.log(addSalesArray)
        console.log(item)
        if (item?._id) {
            filterItems = addSalesArray.filter(el => !(el._id === item._id && el.createdAt === item.createdAt));
            setDeleteSales([...deleteSales, item?._id]);
        } else {
            filterItems = addSalesArray
                .filter(el => !(el.product === item.product && el.size === item.size && el.color === item.color && !el?._id));
        };
        console.log(filterItems);
        setAddSalesArray(filterItems);
    };



    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
                    {`${title} ${totalAmount > 0 ? '- Total amount' + ' ' + totalAmount : ''} ${totalDiscount > 0 ? ' - Total discount' + ' ' + totalDiscount : ''}`}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ minHeight: 500 }} dividers>
                    <div className='row'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Product</label>
                            <Select
                                options={modifiedProductsArray}
                                isSearchable={true}
                                placeholder="Select an option"
                                value={product}
                                onChange={(product) => setProduct(product)}
                                isDisabled={action === 'view'}
                            />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Price</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Price" value={price} disabled={isCalculateInService} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Size</label>
                            <select className="form-control" id="exampleFormControlSelect1" placeholder='Select Product' value={size} onChange={(e) => setSize(e.target.value)} disabled={action === 'view'}>
                                <option>Select size</option>
                                {[...sizes, ...((sizes.includes(selectedSalesShop?.size) || !selectedSalesShop?.size) ? [] : [selectedSalesShop?.size])].map(size => {
                                    return <option value={size}>{size}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Color</label>
                                    <select className="form-control" id="exampleFormControlSelect1" placeholder='Select Color' value={color} onChange={(e) => setColor(e.target.value)} disabled={action === 'view'}>
                                        <option>Select color</option>
                                        {[...colors, ...((colors.includes(selectedSalesShop?.color) || !selectedSalesShop?.color) ? [] : [selectedSalesShop?.color])].map(color => {
                                            return <option value={color}>{color}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Quantity {product.value && size && color && <span style={{ fontSize: 12, color: remainingQuantity < 10 ? "red" : "green" }}>(Remaining - {remainingQuantity})</span>}</label>
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Quantity" value={quantity} onChange={(e) => quantityHandler(e.target.value > 0 ? e.target.value : 1)} disabled={action === 'view'} />
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Amount</label>{discount > 0 && <span style={{ fontSize: 10, marginLeft: 10, color: "red" }}>Discount - {discount}</span>}
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Price" value={amount} onChange={(e) => setAmount(e.target.value > 0 ? e.target.value : 1)} />
                                </div>
                                {isCalculateInService && <div className="row" style={{ position: "relative" }}>
                                    {isEnabledDiscount && !dealOfTheDayDateValidation && <div style={{ position: "absolute", color: "red", fontSize: 12, marginTop: 5, textAlign: "right" }}>
                                        {discountObject?.item && discountObject?.type === 'percentage' ? `* Price calculate for used ${discountObject?.value}% off for more than ${discountObject?.item} - discount (${symbol}${discount})` :
                                            discountObject?.item && discountObject?.type === 'amount' ? `* Price calculate for used ${discountObject?.value} off for more than ${discountObject?.item} - discount (${symbol}${discount})` : ''}
                                    </div>}
                                    {dealOfTheDayDateValidation && <div style={{ position: "absolute", color: "red", fontSize: 12, marginTop: 5, textAlign: "right" }}>
                                        * Price calculate for used deal of the day price - discount({symbol}{discount})
                                    </div>}
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <button type="button" class="btn btn-outline-primary" disabled={action === 'view'} onClick={addSale}>Add</button>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Color</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    <th>Discount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addSalesArray.map(item => <tr>
                                    <td>{item.productName}</td>
                                    <td>{item.size}</td>
                                    <td>{item.price}</td>
                                    <td>{item.color}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.discount}</td>
                                    {/* <td style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ width: 50 }}>{item?.colors?.value}</div>
                                        <div style={{ backgroundColor: item?.colors?.value, width: 20, height: 20, borderRadius: "50%", border: "0.5px solid black", marginLeft: 10 }}></div>
                                    </td>
                                    <td>{item.quantity}</td> */}
                                    <td>
                                        {/* {item?._id && <Edit onClick={() => action !== 'view' && editItem(item)} fontSize="medium" sx={{ color: "green", cursor: "pointer", marginRight: "10px" }} />} */}
                                        {<Delete onClick={() => action !== 'view' && removeItem(item)} fontSize="medium" sx={{ color: "red", cursor: "pointer" }} />}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
                {action !== 'view' && <DialogActions>
                    <button type="button" class="btn btn-secondary" onClick={handleClose}>Close</button>
                    {action === "create" && <button className="btn btn-primary" disabled={isLoading} onClick={createSaleShop}>
                        Create
                    </button>}
                    {action === "edit" && <button className="btn btn-primary" disabled={isLoading} onClick={updateSaleShop}>
                        Update
                    </button>}
                </DialogActions>}
            </BootstrapDialog>
        </>
    );
}
