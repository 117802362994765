import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Circles } from 'react-loader-spinner';

import { uploadFileToFirebase } from '../../util/firebaseService';
import * as categoryService from '../../services/categoryService';
import style from './category.module.css';
import TableSkelton from '../shared/table/tabelSkelton';
import TableHeadSection from '../shared/table/tableHead';
import TableEmpty from '../shared/table/tableEmpty';
import TableBodyRow from '../shared/table/tableBodyRow';
import TableCellAction from '../shared/table/tabelCellAction';
import TablePaginationSection from '../shared/table/tablePagination';
import { categoryActions } from '../../store/categorySlice';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import { errorNotify } from '../../config/notify';
import PrimaryButton from '../shared/addButton';

const Category = () => {
    const dispatch = useDispatch();

    const categories = useSelector((state) => state.category.categories);
    const isRefreshCategories = useSelector((state) => state.category.isRefreshCategories);

    const [id, setId] = useState("");
    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [uploadImage, setUploadImage] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [previousImage, setPreviousImage] = useState("");

    const count = useSelector((state) => state.category.count);
    const isLoading = useSelector((state) => state.category.isLoading);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const tableTitles = ["", "Name", "Products", "Visible", "Created At", "updated At", "Action"];

    useEffect(() => {
        getCategories();
    }, [page, rowsPerPage]);

    useEffect(() => {
        if(isRefreshCategories) {
            getCategories();
        }
    }, [isRefreshCategories]);

    const getCategories = () => {
        const params = {
            page,
            pageSize: rowsPerPage
          };
        dispatch(categoryActions.loadingHandler(true));
        dispatch(categoryService.getCategories(params));
    }

    const createCategory = () => {
        dispatch(categoryService.createCategory(name, uploadImage));
        setName("");
        setFile("");
        setIsEdit(false);
        setUploadImage("");
        setPreviousImage("");
    };

    const updateCategory = () => {
        return errorNotify("Features are locked in the demo version.");
        dispatch(categoryService.updateCategory(name, uploadImage, id, previousImage && previousImage !== uploadImage ? previousImage : undefined));
        setName("");
        setFile(""); 
        setIsEdit(false);
        setUploadImage("");
        setPreviousImage("");
    };

    const deleteCategory = (category) => {
        return errorNotify("Features are locked in the demo version.");
        setIsDeleteModalOpen(true);
        setId(category._id);
        setPreviousImage(category.image)
    };

    const editHandler = (category) => {
        setName(category.name);
        setUploadImage(category.image);
        setId(category._id);
        setIsEdit(true);
        setPreviousImage(category.image)
    };

    const resetHandler = () => {
        setName("");
        setFile("");
        setIsUploadingImage(false); 
        setUploadImage(""); 
        setIsEdit(false);
    }

    const imageUpload = async (event) => {
        const selectedFile = event.target.files[0];
        console.log(event.target.files[0]);
        setIsUploadingImage(true);
        setFile({ file: selectedFile });
        setUploadImage("");
        uploadFileToFirebase(`categories/${selectedFile.name}-${new Date()}`, selectedFile)
            .then(downloadUrl => {
                setUploadImage(downloadUrl);
                setIsUploadingImage(false);
                event.target.value = null;
            })
            .catch(error => {
                console.error("Error uploading file:", error);
            });
    };

    const visibleHandler = (value, id) => {
        dispatch(categoryService.updateVisible(id, value));
    }

    const deleteModalHandler = (modalAction, reason) => {
        if(modalAction === "confirm") {
          dispatch(categoryService.deleteCategory(id, reason, previousImage));
        }
        setIsDeleteModalOpen(false);
        setId("");
      }

    return (
        <div className={style.container}>
            {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}
            <div className={style.create_category}>
                <div className="form-group" style={{ marginRight: 10 }}>
                    <label for="exampleFormControlInput1" className={style.input_label}>Name</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Category Name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group" style={{ marginRight: 10 }}>
                    <label for="exampleFormControlInput1" className={style.input_label}>Image</label>
                    <div className='d-flex align-items-center'>
                        <input type="file" className="form-control" id="exampleFormControlInput1" placeholder="Image" accept="image/*" onChange={imageUpload} />
                        <Circles
                            height="30"
                            color="#4fa94d"
                            ariaLabel="circles-loading"
                            visible={isUploadingImage}
                        />
                    </div>
                </div>
                {!isEdit && <PrimaryButton name="Create" module="Category" onClick={createCategory} disabled={isUploadingImage} />}
                {isEdit && <button style={{ height: "40px", fontSize: 14, marginRight: 10 }} type="button" disabled={isUploadingImage} className="btn btn-primary" onClick={updateCategory}>Update</button>}
                {(isEdit || name) && <button style={{ height: "40px", fontSize: 14 }} type="button" className="btn btn-light" onClick={resetHandler}>Cancel</button>}
                {uploadImage && <img src={uploadImage} style={{height: 50, width: 50, marginLeft: 10, borderRadius: 5}} />}
            </div>

            <div class="alert alert-primary my-2" role="alert">
                You can add a new category or update an existing category type here. You can manage which categories are displayed to the customer on the website and mobile app.
            </div>
            
            <div className={style.table}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHeadSection titles={tableTitles} />
                        {!isLoading && categories.length > 0 &&<TableBody>
                            {categories.map((category) => (
                                <TableBodyRow>
                                    <TableCell align="start"><img style={{ height: 50, width: 50, borderRadius: 10, cursor: "pointer" }} src={category.image} onClick={() => window.open(category.image, "_blank")} /></TableCell>
                                    <TableCell >{category.name}</TableCell>
                                    <TableCell >{'5'}</TableCell>
                                    <TableCell ><Switch checked={category?.isVisible} onChange={(e) => visibleHandler(e.target.checked, category._id)}  defaultChecked /></TableCell>
                                    <TableCell >{moment(category.createdAt).format("yyyy-MM-DD")}</TableCell>
                                    <TableCell >{moment(category.updatedAt).format("yyyy-MM-DD")}</TableCell>
                                    <TableCellAction module="Category" editItem={() => editHandler(category)} deleteItem={() => deleteCategory(category)} />
                                </TableBodyRow>
                            ))}
                        </TableBody>}
                        {!isLoading && !categories.length && <TableEmpty />}
                        {isLoading && <TableSkelton row={4} column={7} />}
                    </Table>
                </TableContainer>
                <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            </div>
        </div >
    )
};

export default Category;