import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Select from 'react-select';
import { Delete, Edit, PrintSharp, RemoveRedEyeRounded } from '@mui/icons-material';
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tabs, Tab, Box } from '@mui/material';

import { steps, stepColors } from '../../config/config';
import AddOrEditSalesShop from './addOrEditSalesShop/addOrEditSalesShop';
import * as SalesShopService from '../../services/saleShopService';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import style from './salesShop.module.css';
import TableSkelton from '../shared/table/tabelSkelton';
import TableEmpty from '../shared/table/tableEmpty';
import TableHeadSection from '../shared/table/tableHead';
import TablePaginationSection from '../shared/table/tablePagination';
import TableBodyRow from '../shared/table/tableBodyRow';
import TableCellAction from '../shared/table/tabelCellAction';
import { errorNotify } from '../../config/notify';
import { salesShopActions } from '../../store/salesShop';
import PrimaryButton from '../shared/addButton';

import { PrintProvider, Print, NoPrint, useReactToPrint } from 'react-to-print';
import Receipt from './receipt';

const SalesShop = () => {
  const dispatch = useDispatch();
  const salesManagementRef = useRef();
  const salesShop = useSelector((state) => state.salesShop.salesShop);
  const count = useSelector((state) => state.salesShop.count);
  const isRefreshItems = useSelector((state) => state.salesShop.isRefreshItems);
  const products = useSelector((state) => state.product.products);
  const [selectedSale, setSelectedSale] = useState();
  const [sale, setSale] = useState();

  const isLoading = useSelector((state) => state.salesShop.isLoading);
  const isOpenModal = useSelector((state) => state.salesShop.isOpenModal);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [product, setProduct] = useState("all");
  const [salesShopId, setSalesShopId] = useState("");
  const [modifiedProductsArray, setModifiedProductsArray] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState("");
 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableTitles = ["ID", "Total Amount", "Total Quantity", "Total Discount", "Create At", "Update At", "Print", "Action"];

  useEffect(() => {
    getOrder();
  }, [product, page, rowsPerPage]);

  useEffect(() => {
    if (isRefreshItems) {
      getOrder();
    }
  }, [isRefreshItems]);

  const getOrder = () => {
    const params = {
      page,
      pageSize: rowsPerPage
    };
    if (product.value !== "all") {
      params.product = product.value;
    }

    dispatch(salesShopActions.loadingHandler(true));
    dispatch(SalesShopService.getSalesShop(params));
  }

  useEffect(() => {
    if (products.length > 0) {
      const newProductsArray = [{ value: "all", label: "All" }];
      products.forEach(product => {
        newProductsArray.push({ value: product._id, label: product.name });
      });
      setModifiedProductsArray(newProductsArray);
    }
  }, [products]);

  const deleteSaleShop = (sale) => {
    setIsDeleteModalOpen(true);
    setSalesShopId(sale._id);
    setSelectedSale(sale);
  }

  const createSaleShop = () => {
    dispatch(salesShopActions.modalHandler(true));
    setModalTitle("Create New Sale");
    setModalAction("create");
    setSalesShopId(undefined);
  }

  const editSaleShop = (p) => {
    setModalTitle("Edit Sale");
    setModalAction("edit");
    dispatch(salesShopActions.modalHandler(true));
    setSalesShopId(p._id);
  }

  const viewSaleShop = (p) => {
    console.log("109");
    setModalTitle("Sale Details");
    setModalAction("view");
    dispatch(salesShopActions.modalHandler(true));
    setSalesShopId(p._id);
  }

  const deleteModalHandler = (modalAction, reason) => {
    if (modalAction === "confirm") {
      selectedSale.data.forEach(el => {
        dispatch(SalesShopService.deleteSaleShop(el._id, reason));
      })
    }
    setIsDeleteModalOpen(false);
    setSalesShopId("");
  }

  const handlePrint = useReactToPrint({
    content: () => {
      return salesManagementRef.current;
    },
  });


  return (
    <div className={style.container}>
      <div className={style.header_section}>
        <div>
          <PrimaryButton name="Create Sale" module="Sales Shop" onClick={createSaleShop} />
        </div>
      </div>

      <div class="alert alert-primary my-2" role="alert">
        You can manage daily sales here.
      </div>

      {isOpenModal &&
        <div className={style.product_form}>
          <AddOrEditSalesShop
            title={modalTitle}
            modalOpen={isOpenModal}
            action={modalAction}
            saleShopId={salesShopId} />
        </div>
      }
      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}

      <div className={style.table}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadSection titles={tableTitles} />

            {!isLoading && salesShop.length > 0 && <TableBody>
              {salesShop.map((sale) => (
                <TableBodyRow>
                  <TableCell component="th" scope="row">{sale?._id}</TableCell>
                  <TableCell component="th" scope="row">{sale?.totalAmount}</TableCell>
                  <TableCell component="th" scope="row">{sale?.totalQuantity}</TableCell>
                  <TableCell component="th" scope="row">{sale?.totalDiscount}</TableCell>
                  <TableCell component="th" scope="row">{moment(new Date((sale.data[0].createdAt))).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                  <TableCell component="th" scope="row">{moment(sale.data[0].updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                  <TableCell component="th" scope="row">
                  <Receipt sale={sale} />
                  </TableCell>
                  <TableCellAction module="Sales Shop" viewItem={() => viewSaleShop(sale)} editItem={() => editSaleShop(sale)} deleteItem={() => deleteSaleShop(sale)} />
                </TableBodyRow>
              ))}
            </TableBody>}

            {!isLoading && !salesShop.length && <TableEmpty />}

            {isLoading && <TableSkelton row={4} column={8} />}
          </Table>
        </TableContainer>
        <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      </div>
    </div >
  );
};

export default SalesShop;
