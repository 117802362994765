import React, { useState, useEffect } from 'react'
import image from './41.gif'
import './login.css';
import { Login } from '../../services/authService';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';



export default function Background() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const loginDetailsHandler = () => {
    dispatch(Login(email, password, navigate));
  };

  return (
    <div className="login_container">
      <div style={{ width: "75%" }}>
        <img src={image} width="100%" height="100%" />
      </div>
      <div className='right_container'>
        <input placeholder="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input className="form-control my-4" value={password} type="password" onChange={(b) => setPassword(b.target.value)} placeholder='password' />
        <button class="btn btn-secondary" onClick={loginDetailsHandler}>Sign In</button>
      </div>
    </div>

  );
}
