import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Switch } from '@mui/material';

import * as productService from "../../services/productServices"
import style from './products.module.css';
import AddOrEditProduct from './addOrEditProduct/addOrEditProduct';
import { productActions } from '../../store/productSlice';
import TableSkelton from '../shared/table/tabelSkelton';
import TableEmpty from '../shared/table/tableEmpty';
import TableHeadSection from '../shared/table/tableHead';
import TablePaginationSection from '../shared/table/tablePagination';
import TableCellAction from '../shared/table/tabelCellAction';
import { Search } from '@mui/icons-material';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import moment from 'moment';
import TableBodyRow from '../shared/table/tableBodyRow';
import { errorNotify } from '../../config/notify';
import PrimaryButton from '../shared/addButton';


const Products = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const isRefreshItems = useSelector((state) => state.product.isRefreshItems);
  const [previousImages, setPreviousImages] = useState([]);

  const count = useSelector((state) => state.product.count);
  const isLoading = useSelector((state) => state.product.isLoading);
  const isOpenModal = useSelector((state) => state.product.isOpenModal);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchText, setSearchText] = useState("");

  const [productId, setProductId] = useState("");

  // const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const tableTitles = ["Name", "Category", "Price", "Quantity", "Status", "Visible", "Created At", "updated At", "Action"];

  useEffect(() => {
      getProducts();
  }, [page, rowsPerPage, searchText]);

  useEffect(() => {
    if(isRefreshItems) {
      getProducts();
    }
  }, [isRefreshItems]);

  const getProducts = () => {
    const params = {
      page,
      pageSize: rowsPerPage
    };
    if(searchText) {
      params.searchText = searchText;
    }
    dispatch(productActions.loadingHandler(true));
    dispatch(productService.getProducts(params));
  }

  const deleteHandler = (p) => {
    return errorNotify("Features are locked in the demo version.");
    setIsDeleteModalOpen(true);
    setProductId(p._id);
    setPreviousImages(p.images);
    // axios({
    //   method: 'delete',
    //   url: `https://shop-b6ad.onrender.com/products/${p._id}`,
    //   data: {
    //     name: product,
    //     category: category
    //   }
    // }).then(res => {
    //   dispatch(getProduct());
    // });
  };

  // const modalHandler = (isModalOpen) => {
  //   setModalOpen(isModalOpen);
  // };

  const createProduct = () => {
    dispatch(productActions.modalHandler(true));
    setModalTitle("Create New Product");
    setModalAction("create");
    // setModalOpen(true);
    setProductId(undefined);
  };

  const editProduct = (p) => {
    dispatch(productActions.modalHandler(true));
    setModalTitle("Edit Product");
    setModalAction("edit");
    // setModalOpen(true);
    setProductId(p._id);
  };

  const viewProduct = (p) => {
    dispatch(productActions.modalHandler(true));
    setModalTitle("Product Details");
    setModalAction("view");
    // setModalOpen(true);
    setProductId(p._id);
  };

  const searchHandler = (text) => {
    setPage(0);
    setSearchText(text);
  };

  const deleteModalHandler = (modalAction, reason) => {
    console.log(previousImages);
    console.log(previousImages.slice(0, -1));
    if(modalAction === "confirm") {
      dispatch(productService.deleteProduct(productId, reason, previousImages.slice(0, -1)));
    }
    setIsDeleteModalOpen(false);
    setProductId("");
  }

  const visibleHandler = (value, id) => {
    dispatch(productService.updateVisible(id, value));
}

  return (
    <div className={style.container}>
      <div className="d-flex justify-content-between align-items-center">
        <div class="input-group mb-3" style={{width: 500}}>
          <input type="text" class="form-control" placeholder="Search by product name" aria-label="Username" aria-describedby="basic-addon1" value={searchText} onChange={(e) => searchHandler(e.target.value)} />
          <div class="input-group-prepend" style={{backgroundColor: "#0000000d"}}>
            <span class="input-group-text" id="basic-addon1" style={{backgroundColor: "#0000000d"}}><Search /></span>
          </div>
        </div>
        <div>
          <PrimaryButton name="Create Product" module="Products" onClick={createProduct} />
        </div>
      </div>

      <div class="alert alert-primary my-2" role="alert">
        You can add a new product or update an existing product type here. You can manage which products are displayed to the customer on the website and mobile app.
      </div>

      {isOpenModal &&
        <div className={style.product_form}>
          <AddOrEditProduct
            title={modalTitle}
            modalOpen={isOpenModal}
            action={modalAction}
            productId={productId} />
        </div>
      }

      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}

      <div className={style.table}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadSection titles={tableTitles} />

            {!isLoading && products.length > 0 && <TableBody>
              {products.map((p) => (
                <TableBodyRow>
                  <TableCell component="th" scope="row">{p?.name}</TableCell>
                  <TableCell align="">{p?.category?.name}</TableCell>
                  <TableCell component="th" scope="row">{p?.price}</TableCell>
                  <TableCell component="th" scope="row">{p?.totalQuantity}</TableCell>
                  <TableCell component="th" scope="row" style={{color: p?.totalQuantity <= 0 ? "red" : p?.totalQuantity <= 10 ? "#fbb50a" : "green", fontWeight: 600, fontSize: 12}}>{p?.totalQuantity === 0 ? "Out Of Stock" : p?.totalQuantity <= 10 ? "Low Stock" : "In Stock"}</TableCell>
                  <TableCell ><Switch checked={p?.isVisible} onChange={(e) => visibleHandler(e.target.checked, p?._id)} /></TableCell>
                  <TableCell component="th" scope="row">{moment(p?.createdAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCell component="th" scope="row">{moment(p?.updatedAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCellAction module="Products" viewItem={() => viewProduct(p)} editItem={() => editProduct(p)} deleteItem={() => deleteHandler(p)} />
                </TableBodyRow>
              ))}
            </TableBody>}

            {!isLoading && !products.length && <TableEmpty />}

            {isLoading && <TableSkelton row={4} column={9} />}
          </Table>
        </TableContainer>
        <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      </div>
    </div >
  );
};

export default Products;