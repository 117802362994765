import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Circles } from 'react-loader-spinner';

import style from "./addOrEditProduct.module.css";
import * as categoryService from '../../../services/categoryService';
import { deleteFile, uploadFileToFirebase } from '../../../util/firebaseService';
import * as productService from "../../../services/productServices";
import { warningNotify } from '../../../config/notify';
import { productActions } from '../../../store/productSlice';
import { Add, Delete, Edit } from '@mui/icons-material';
import { resizeImage } from '../../../util/imageResizer';
import { colorOptions, sizesOptions } from '../../../config/config';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : "black",
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};


export default function AddOrEditProduct({ title, modalOpen, action, modalHandler, productId }) {
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const products = useSelector((state) => state.product.products);
    const categories = useSelector((state) => state.category.categories);

    const [name, setName] = useState("");
    const [price, setPrice] = useState();
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState();
    const [quantity, setQuantity] = useState("");
    const [category, setCategory] = useState("");
    const [discount, setDiscount] = useState({
        type: "",
        item: "",
        value: ""
    });
    const [isVisible, setIsVisible] = useState(true);
    const [selectedSize, setSelectedSize] = useState("");

    const [file, setFile] = useState("");
    const [uploadImages, setUploadImages] = useState([]);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [isEnabledDiscount, setIsEnabledDiscount] = useState(false);
    const [isEnabledDealOfTheDay, setIsEnabledDealOfTheDay] = useState(false);
    const [items, setItems] = useState([]);
    const [iseEditItem, setIseEditItem] = useState(false);
    const [description, setDescription] = useState("");
    const [dealOfThePrice, setDealOfThePrice] = useState(0);
    const [dealOfTheDate, setDealOfTheDate] = useState("");
    const [removeImageArray, setRemoveImageArray] = useState([]);

    const [selectedItemsToEdit, setSelectedItemsToEdit] = useState({});
    const [displayOrder, setDisplayOrder] = useState();

    useEffect(() => {
        if (productId) {
            const product = products.find(el => el._id === productId);
            setName(product.name);
            setCategory(product.category._id);
            setItems(product.items);
            setPrice(product.price);
            setIsEnabledDiscount(product.isEnabledDiscount);
            setDescription(product.description);
            setDisplayOrder(product?.displayOrder);
            setDiscount({
                type: product.discount.type,
                item: product.discount.item,
                value: product.discount.value
            });
            setUploadImages(product.images);
            setIsEnabledDealOfTheDay(product.isEnabledDealOfTheDay);
            setDealOfThePrice(product.dealOfThePrice);
            setDealOfTheDate(product.dealOfTheDate);
        }
    }, [productId]);

    useEffect(() => {
        dispatch(categoryService.getCategories());
    }, []);

    const handleClose = () => {
        dispatch(productActions.modalHandler(false));
    };

    const createProduct = () => {
        if (isValidationFailed()) {
            return;
        }
        const productJson = {
            name,
            category,
            images: uploadImages,
            price,
            items,
            // colors,
            // sizes,
            // quantity,
            discount,
            isVisible,
            isEnabledDiscount,
            description,
            isEnabledDealOfTheDay,
            dealOfThePrice,
            dealOfTheDate,
            displayOrder
        }
        console.log(productJson);
        dispatch(productService.createProduct(productJson, removeImageArray));
    }

    const updateProduct = () => {
        if (isValidationFailed()) {
            return;
        }
        const productJson = {
            name,
            category,
            images: uploadImages,
            price,
            // colors,
            // sizes,
            // quantity,
            items,
            discount,
            isVisible,
            isEnabledDiscount,
            description,
            isEnabledDealOfTheDay,
            dealOfThePrice,
            dealOfTheDate,
            displayOrder
        }
        console.log(productJson, productId);
        dispatch(productService.updateProduct(productJson, productId, removeImageArray));
    }

    const imageUpload = async (event) => {
        setIsUploadingImage(true);
        const selectedFile = event.target.files[0];
        const resizedImage = await resizeImage(selectedFile, 950, 1050);
        setFile({ file: resizedImage });
        uploadFileToFirebase(`products/${selectedFile.name}-${new Date()}`, resizedImage)
            .then(downloadUrl => {
                setUploadImages([...uploadImages, downloadUrl]);
                setIsUploadingImage(false);
                event.target.value = null;
            })
            .catch(error => {
                console.error("Error uploading file:", error);
            });
    };

    const removeSelectedImage = (url) => {
        if (uploadImages?.length < 4) {
            return warningNotify("Please keep at least 3 images.");
        }
        setUploadImages(uploadImages.filter(el => el !== url));
        setRemoveImageArray([...removeImageArray, url]);
    }

    const isValidationFailed = () => {
        if (!name) {
            warningNotify("Please provide a name.");
            return true;
        }
        if (!category) {
            warningNotify("Please select a category.");
            return true;
        }
        if (!price) {
            warningNotify("Please enter the price.");
            return true;
        }
        // if (!quantity) {
        //     warningNotify("Please enter the quantity.");
        //     return true;
        // }
        // if (!colors.length) {
        //     warningNotify("Please pick a color.");
        //     return true;
        // }
        // if (!sizes.length) {
        //     warningNotify("Please provide a size.");
        //     return true;
        // }
        if (!items.length) {
            warningNotify("Please provide a size, colors, quantity.");
            return true;
        }
        if (isEnabledDiscount) {
            if (!discount.type) {
                warningNotify("Please pick a discount type.");
                return true;
            }
            if (!discount.item) {
                warningNotify("Please enter the items count.");
                return true;
            }
            if (!discount.value) {
                warningNotify("Please enter the value.");
                return true;
            }
        }
        if (uploadImages.length < 3) {
            warningNotify("Please upload at least 3 images.");
            return true;
        }
        return false;
    }

    const addItem = () => {
        if (!selectedSize) {
            return warningNotify("Please select a size.");
        }
        if (!colors) {
            return warningNotify("Please pick colors.");
        }
        // if (quantity == "") {
        //     console.log(quantity)
        //     return warningNotify("Please enter the quantity.");
        // }
        if (!iseEditItem && items.some(el => el.size === selectedSize && el.colors.value === colors.value)) {
            return warningNotify("Already added size and color.");
        }
        if (iseEditItem) {
            const newItem = {
                size: selectedSize,
                colors,
                // quantity: +quantity,
            };
            if(selectedItemsToEdit?._id) {
                newItem._id = selectedItemsToEdit?._id;
            }

            const filterItems = items.filter(el => el.size !== selectedSize);
            setItems([...filterItems, newItem]);
        } else {
            setItems([...items, {
                size: selectedSize,
                colors,
                // quantity: +quantity,
                quantity: 0,
            }]);
        }
        resetItem();
    }

    const editItem = (item) => {
        console.log(item);
        setSelectedSize(item.size);
        setColors(item.colors);
        setQuantity(+item.quantity);
        setIseEditItem(true);
        setSelectedItemsToEdit(item);
    }

    const resetItem = () => {
        setSelectedSize("");
        setColors("");
        setQuantity("");
        setIseEditItem(false);
        setSelectedItemsToEdit({});
    };

    const removeItem = (size, color) => {
        console.log(size, color);
        console.log(items);
        const filterItems = items.filter(el => !(el.size === size && el.colors.value === color));
        setItems([...filterItems]);
    }

    console.log(removeImageArray)

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                maxWidth="xl"
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className='row'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Name</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Product Name" value={name} onChange={(e) => setName(e.target.value)} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Category</label>
                            <select className="form-control" id="exampleFormControlSelect1" placeholder='Category' value={category} onChange={(e) => setCategory(e.target.value)} disabled={action === 'view'}>
                                <option>Select category</option>
                                {categories.map(category => {
                                    return <option value={category._id}>{category.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Selling Price</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value > 0 ? e.target.value : 1)} disabled={action === 'view'} />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Sizes</label>
                            <select className="form-control" id="exampleFormControlSelect1" placeholder='Category' value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)} disabled={action === 'view' || iseEditItem}>
                                <option>Select size</option>
                                {sizesOptions.map(size => {
                                    return <option value={size.value}>{size.label}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Color</label>
                            {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                // isMulti
                                options={colorOptions}
                                styles={colorStyles}
                                value={colors}
                                onChange={(color) => setColors(color)}
                                isDisabled={action === 'view'}
                            /> */}
                            <Select
                                options={colorOptions}
                                styles={colorStyles}
                                value={colors}
                                onChange={(color) => setColors(color)}
                                isDisabled={action === 'view' || iseEditItem && selectedItemsToEdit?._id}
                                placeholder={"Select Color"}
                            />
                        </div>
                        {/* <div className="col-2">
                            <label for="exampleFormControlInput1" className={style.input_label}>Quantity</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value > 0 ? e.target.value : 0)} disabled={action === 'view'} />
                        </div> */}
                        <div className="col-2" style={{ display: "flex", alignItems: "center" }}>
                            <button disabled={action === 'view'} className="btn btn-primary mt-4" style={{ fontSize: 10 }} onClick={addItem}>{iseEditItem ? "Update" : "Add"}</button>
                            {iseEditItem && <button disabled={action === 'view'} className="btn btn-secondary mt-4" style={{ fontSize: 10, marginLeft: "10px" }} onClick={resetItem}>Cancel</button>}
                        </div>
                    </div>
                    {items.length > 0 &&<div className='row mt-4'>
                        <div className="col-4">
                            
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Size</th>
                                            <th scope="col">Color</th>
                                            <th>Quantity</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => <tr>
                                            <td>{item.size}</td>
                                            <td style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{width: 50}}>{item?.colors?.value}</div> 
                                                <div style={{ backgroundColor: item?.colors?.value, width: 20, height: 20, borderRadius: "50%", border: "0.5px solid black", marginLeft: 10 }}></div>
                                            </td>
                                            <td>{item.quantity}</td>
                                            {/* <td>
                                                {item?._id && <Edit onClick={() => action !== 'view' && editItem(item)} fontSize="medium" sx={{ color: "green", cursor: "pointer", marginRight: "10px" }} />}
                                                {!item?._id && <Delete onClick={() => action !== 'view' && removeItem(item.size, item?.colors?.value)} fontSize="medium" sx={{ color: "red", cursor: "pointer" }} />}
                                            </td> */}
                                        </tr>)}
                                    </tbody>
                                </table>
                            
                        </div>
                    </div>}
                    <div className='row mt-4'>
                    <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Display Order</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Display Order" value={displayOrder} onChange={(e) => setDisplayOrder(e.target.value < 1 ? 1 : e.target.value)} disabled={action === 'view'} />
                        </div>
                        <div className="col-8">
                            <label for="exampleFormControlInput1" className={style.input_label}>Description</label>
                            <textarea type="number" className="form-control" id="exampleFormControlInput1" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} disabled={action === 'view'} />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-12">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" checked={isEnabledDiscount} onChange={(e) => setIsEnabledDiscount(e.target.checked)} id="flexSwitchCheckDefault" disabled={action === 'view'} />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Enable Discount</label>
                            </div>
                            {isEnabledDiscount && <div className="row mt-2">
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Discount Type</label>
                                    <select className="form-control" id="exampleFormControlSelect1" placeholder='Category' value={discount.type} onChange={(e) => setDiscount({ ...discount, type: e.target.value })} disabled={action === 'view'}>
                                        <option>Select type</option>
                                        <option value="percentage">Percentage</option>
                                        <option value="amount">Amount</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Item</label>
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Item" value={discount.item} onChange={(e) => setDiscount({ ...discount, item: e.target.value > 0 ? e.target.value : 1 })} disabled={action === 'view'} />
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Value</label>
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Value" value={discount.value} onChange={(e) => setDiscount({ ...discount, value: e.target.value > 0 ? e.target.value : 1 })} disabled={action === 'view'} />
                                </div>
                            </div>}
                            {isEnabledDiscount && <div className="row" style={{ position: "relative" }}>
                                <div style={{ position: "absolute", color: "red", fontSize: 12, marginTop: 5 }}>
                                    {discount && discount?.item && discount?.type === 'percentage' ? `* ${discount.value}% off for more than ${discount?.item}` :
                                        discount && discount?.item && discount?.type === 'amount' ? `* ${discount?.value} off for more than ${discount?.item}` : ''}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-12">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" checked={isEnabledDealOfTheDay} onChange={(e) => setIsEnabledDealOfTheDay(e.target.checked)} id="flexSwitchCheckDefault" disabled={action === 'view'} />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Enable Deal Of the day discount</label>
                            </div>
                            {isEnabledDealOfTheDay && <div className="row mt-2">
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>New Price</label>
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Item" value={dealOfThePrice} onChange={(e) => setDealOfThePrice(e.target.value > 0 ? e.target.value : 1)} disabled={action === 'view'} />
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Until To</label>
                                    <input type="date" className="form-control" id="exampleFormControlInput1" placeholder="Value" value={dealOfTheDate} onChange={(e) => setDealOfTheDate(e.target.value)} disabled={action === 'view'} />
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-4'>
                            <input class="form-check-input" type="checkbox" checked={isVisible} onChange={(e) => setIsVisible(e.target.checked)} id="flexCheckDefault" disabled={action === 'view'} />
                            <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: 10 }}>
                                Should it appear on the customers's site/app?
                            </label>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Images</label>
                                    <div className='d-flex align-items-center'>
                                        <input type="file" className="form-control" id="exampleFormControlInput1" placeholder="Image" accept="image/*" onChange={imageUpload} disabled={action === 'view'} />
                                        <Circles
                                            height="30"
                                            color="#4fa94d"
                                            ariaLabel="circles-loading"
                                            visible={isUploadingImage}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 d-flex flex-wrap">
                                {uploadImages.map(image =>
                                    <div
                                        style={{ position: "relative" }}
                                    >
                                        <img style={{ width: 120, height: 120, marginBottom: 10, marginRight: 10, cursor: "pointer" }} src={image} />
                                        <IconButton
                                            onClick={() => removeSelectedImage(image)}
                                            sx={{
                                                position: 'absolute',
                                                right: 0,
                                                left: 0,
                                                top: 0,
                                                bottom: 0,
                                                color: "black",
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                {action !== "view" && <DialogActions>
                    <button type="button" class="btn btn-secondary" onClick={handleClose}>Close</button>
                    {action === "create" && <button className="btn btn-primary" onClick={createProduct}>
                        Create
                    </button>}
                    {action === "edit" && <button className="btn btn-primary" onClick={updateProduct}>
                        Update
                    </button>}
                </DialogActions>}
            </BootstrapDialog>
        </>
    );
}
