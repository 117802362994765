export const productCalculation = (dealOfTheDayDateValidation, product, quantity) => {
    const price = dealOfTheDayDateValidation ? +product?.dealOfThePrice : +product?.price;
    if(dealOfTheDayDateValidation) {
        const discountCal = +quantity * +product?.price - quantity * +product?.dealOfThePrice;
        const totalCal = +product?.dealOfThePrice * +quantity;
        return { amount: totalCal, discount: discountCal };
    } else {
      if (product?.isEnabledDiscount && product?.discount?.type === "percentage") {
        console.log(9)
        const discountCal = +quantity >= +product.discount?.item ? (quantity * price) * +product?.discount?.value / 100 : 0;
        console.log(discountCal)
        const totalCal = (+price * +quantity) - discountCal;
        return { amount: +totalCal, discount: +discountCal };
      } else if (product?.isEnabledDiscount && product?.discount?.type === "amount") {
        console.log(14)
        const discountCal = +quantity >= +product.discount?.item ? +product?.discount?.value : 0;
        const totalCal = (price * quantity) - discountCal;
        return { amount: +totalCal, discount: +discountCal };
      } else {
        const totalCal = price * quantity;
        return { amount: +totalCal, discount: 0 };
      }
    }
};