import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import * as stockService from '../../services/stockService';
import * as productService from '../../services/productServices';
import style from './stock.module.css';
import AddOrEditStock from './addOrEditStock/addOrEditStock';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import TableHeadSection from '../shared/table/tableHead';
import TableEmpty from '../shared/table/tableEmpty';
import TableSkelton from '../shared/table/tabelSkelton';
import PrimaryButton from '../shared/addButton';
import TablePaginationSection from '../shared/table/tablePagination';
import TableCellAction from '../shared/table/tabelCellAction';
import moment from 'moment';
import TableBodyRow from '../shared/table/tableBodyRow';
import { staffActions } from '../../store/staffSlice';
import { stockActions } from '../../store/stockSlice';
import { errorNotify } from '../../config/notify';
import { refreshProducts } from '../refresh';


const Stock = () => {
  const dispatch = useDispatch();
  const stocks = useSelector((state) => state.stock.stocks);
  const isRefreshItems = useSelector((state) => state.stock.isRefreshItems);
  const products = useSelector((state) => state.product.products);

  const [product, setProduct] = useState("");
  const [stockId, setStockId] = useState("");

  const [modifiedProductsArray, setModifiedProductsArray] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState("");

  const count = useSelector((state) => state.stock.count);
  const isLoading = useSelector((state) => state.stock.isLoading);
  const isOpenModal = useSelector((state) => state.stock.isOpenModal);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableTitles = ["Name", "Price", "Bill Date", "Created At", "updated At", "Action"];

  useEffect(() => {
    getStocks();
  }, [product, page, rowsPerPage]);

  useEffect(() => {
    if(isRefreshItems) {
      getStocks();
    }
  }, [isRefreshItems]);

  const getStocks = () => {
    const params = {
      page,
      pageSize: rowsPerPage
    };
    if(product.value !== "all") {
      params.product = product.value;
    }
    dispatch(stockActions.loadingHandler(true));
    dispatch(stockService.getStocks(params));
  }

  useEffect(() => {
    if (products.length > 0) {
      const newProductsArray = [{ value: "all", label: "All" }];
      products.forEach(product => {
        newProductsArray.push({ value: product._id, label: product.name });
      });
      setModifiedProductsArray(newProductsArray);
    }
  }, [products]);

  useEffect(() => {
    dispatch(productService.getProducts());
  }, [])

  useEffect(() => {
    refreshProducts();
  }, []);
  

  const deleteHandler = (id) => {
    setIsDeleteModalOpen(true);
    setStockId(id);
  }

  const createStock = () => {
    dispatch(stockActions.modalHandler(true));
    setModalTitle("Create New Stock");
    setModalAction("create");
    // setModalOpen(true);
    setStockId(undefined);
  }

  const editStock = (p) => {
    dispatch(stockActions.modalHandler(true));
    setModalTitle("Edit Stock");
    setModalAction("edit");
    // setModalOpen(true);
    setStockId(p._id);
  }

  const viewStock = (p) => {
    dispatch(stockActions.modalHandler(true));
    setModalTitle("Stock Details");
    setModalAction("view");
    // setModalOpen(true);
    setStockId(p._id);
  }

  const deleteModalHandler = (modalAction, reason) => {
    console.log(modalAction);
    if (modalAction === "confirm") {
      dispatch(stockService.deleteStock(stockId, reason));
    }
    setIsDeleteModalOpen(false);
    setStockId("");
  }

  return (
    <div className={style.container}>
      <div className="d-flex justify-content-between align-items-center">
        <div style={{width: "20%"}}>
        <Select
            options={modifiedProductsArray}
            isSearchable={true}
            placeholder="Search by product"
            value={product}
            onChange={(product) => {
              setPage(0);
              setProduct(product);
            }}
          />
        </div>
        <div>
          <PrimaryButton name="Create Stock" module="Stocks" onClick={createStock} />
        </div>
      </div>

      <div class="alert alert-primary my-2" role="alert">
        You can manage the stock details here. When you add new stock or update stock, the related product quantity will be updated.
      </div>

      {isOpenModal &&
        <div className={style.product_form}>
          <AddOrEditStock
            title={modalTitle}
            modalOpen={isOpenModal}
            // modalHandler={modalHandler}
            action={modalAction}
            stockId={stockId} />
        </div>
      }

      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}

      <div className={style.table}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadSection titles={tableTitles} />

            {!isLoading && stocks.length > 0 && <TableBody>
              {stocks.map((stock) => (
                <TableBodyRow>
                  <TableCell component="th" scope="row">{stock?.name}</TableCell>
                  <TableCell component="th" scope="row">{stock?.price}</TableCell>
                  <TableCell component="th" scope="row">{moment(stock?.date).format("yyyy-MM-DD")}</TableCell>
                  <TableCell component="th" scope="row">{moment(stock?.createdAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCell component="th" scope="row">{moment(stock?.updatedAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCellAction module="Stocks" viewItem={() => viewStock(stock)} editItem={() => editStock(stock)} deleteItem={() => deleteHandler(stock._id)} />
                </TableBodyRow>
              ))}
            </TableBody>}

            {!isLoading && !stocks.length && <TableEmpty />}

            {isLoading && <TableSkelton row={4} column={6} />}
          </Table>
        </TableContainer>
        <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      </div>
    </div >
  );
};

export default Stock;
