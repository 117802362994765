import axios from 'axios';

import { API_HOST } from '../config/config';
import { salesShopActions } from '../store/salesShop';
import { successNotify, errorNotify } from '../config/notify';
import axiosInstance from '../config/axiosInstance';

export const getSalesShop = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/sales-shop/get',
        params: params,
    }).then(res => {
        dispatch(salesShopActions.getSalesShop(res.data?.data));
        dispatch(salesShopActions.loadingHandler(false));
    })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(salesShopActions.loadingHandler(false));
        });
};

export const createSaleShop = (salesShopJson) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/sales-shop/create',
        data: salesShopJson
    })
        .then(() => {
            successNotify("Successfully created sale.");
            dispatch(salesShopActions.modalHandler(false));
            dispatch(salesShopActions.loadingHandler(true));
            dispatch(salesShopActions.refreshItems());
        })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(salesShopActions.loadingHandler(false));
        });
};

export const deleteSaleShop = (id, reason) => (dispatch) => {
    console.log(id)
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/sales-shop/delete/${id}`,
        data: {
            reason,
        }
    }).then(() => {
        dispatch(salesShopActions.loadingHandler(true));
        successNotify("Successfully deleted sale.");
        dispatch(salesShopActions.refreshItems());
    })
        .catch(err => {
            console.log(err.response);
            dispatch(salesShopActions.loadingHandler(false));
            errorNotify(err.response?.data?.message || err.message);
        });
};

export const updateSaleShop = (sales, id) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/sales-shop/update/${id}`,
        data: { sales }
    })
        .then(() => {
            successNotify("Successfully updated sale.");
            dispatch(salesShopActions.refreshItems());
            dispatch(salesShopActions.modalHandler(false));
            dispatch(salesShopActions.loadingHandler(true));
        })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(salesShopActions.loadingHandler(false));
        });
};