// Receipt.js
import React, { useRef } from 'react';
import './receipt.css'; // Import your CSS file
import moment from 'moment';
import { PrintSharp } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';

const ReceiptComponent = React.forwardRef(({ sale }, ref) => {
  return (
    <div ref={ref} className="receipt-container">
      <h2>D96</h2>
      <p>Date: {moment().format('MM/DD/YYYY')}</p>
      <p>receipt: {sale?._id}</p>
      <div className="receipt-items mt-4">
        {[1].map((el, index) => (
          <div key={index} className="receipt-item">
            <div className="item-name" style={{ fontWeight: 600 }}>
              Item
            </div>
            <div className="item-quantity" style={{ fontWeight: 600 }}>
              Quantity
            </div>
            <div className="item-amount" style={{ fontWeight: 600 }}>
              Amount
            </div>
            <div className="item-discount" style={{ fontWeight: 600 }}>
              Discount
            </div>
          </div>
        ))}
        {sale?.data.map((el, index) => (
          <div key={index} className="receipt-item">
            <div className="item-name">{el.productName}</div>
            <div className="item-quantity">{el.quantity}</div>
            <div className="item-amount">{el.amount}</div>
            <div className="item-discount">{el.discount}</div>
          </div>
        ))}
      </div>
      <p className="total-amount">Total Amount: {sale?.totalAmount}</p>
      <p>Thanks for shopping!</p>
    </div>
  );
});

const Receipt = ({ sale }) => {
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => <PrintSharp style={{ cursor: 'pointer', color: 'green' }} />}
        content={() => componentRef.current}
      />
      <ReceiptComponent ref={componentRef} sale={sale} />
    </>
  );
};

export default Receipt;
